// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/nicofetter/proj/hype-clients/hype-gatsby-template/src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("/Users/nicofetter/proj/hype-clients/hype-gatsby-template/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-styleguide-js": () => import("/Users/nicofetter/proj/hype-clients/hype-gatsby-template/src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nicofetter/proj/hype-clients/hype-gatsby-template/.cache/data.json")

